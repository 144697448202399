import { useMemo } from "react";

import { useSearchParams } from "next/navigation";
import { DeepPartial } from "react-hook-form";

/**
 * クエリパラメータを解析し、指定された型`T`のオブジェクトとして返すカスタムフック。
 * URLのクエリパラメータから抽出された値がJSONとして解析可能な場合は、その解析された値を返します。
 * そうでない場合は、文字列として値を返します。
 * クエリパラメーターのキーがブラケット`[]`で区切られている場合、そのキーはネストされたオブジェクトとして解釈されます。
 *
 * @template T - 返されるオブジェクトの型。
 * @returns {T} クエリパラメータを解析した結果を含むオブジェクト。
 *
 * @example
 * // queryString = ?cart[products]=[{variant_id: 1, quantity: 2}]
 * const params = useQueryParams();
 * console.log(params);
 * // { cart: { products: [{ variant_id: 1, quantity: 2 }] } }
 */
export function useQueryParams<T>() {
  const searchParams = useSearchParams();
  const queryKeys = Array.from(searchParams.keys());

  return useMemo<DeepPartial<T>>(() => {
    const regex = /([^\[\]]+)(?:\[(\w+)\])?/;
    return queryKeys.reduce((acc, originalKey) => {
      const value = searchParams.get(originalKey);
      const match = originalKey.match(regex);
      const keys = match ? match.slice(1).filter(Boolean) : [originalKey];
      let current = acc;
      keys.forEach((key, index) => {
        if (index === keys.length - 1) {
          // 最後のキーの場合、値を設定
          try {
            current[key] = JSON.parse(value!);
          } catch {
            current[key] = value;
          }
          return;
        }

        if (!current[key]) current[key] = {};
        current = current[key];
      });
      return acc;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    }, {} as any);
  }, [queryKeys, searchParams]);
}
